import { Network, isDevnet, isTestnet } from '@injectivelabs/networks'

export const IS_PRODUCTION: boolean = import.meta.env.NODE_ENV === 'production'

export const BASE_URL = import.meta.env.VITE_BASE_URL || ''
export const USE_ARCHIVAL_NODE =
  import.meta.env.VITE_USE_ARCHIVAL_NODE === 'true'

export const NETWORK: Network =
  (import.meta.env.VITE_NETWORK as Network) || Network.Internal

export const IS_DEVNET = isDevnet(NETWORK)
export const IS_TESTNET = isTestnet(NETWORK)

const getDMMServiceEndpoint = (): string => {
  if (USE_ARCHIVAL_NODE) {
    return 'https://dmm.staging.injective.network'
  }

  if (IS_DEVNET) {
    return 'https://devnet-1.api.injective.dev'
  }

  return 'https://glp.injective.network'
}

export const DMM_SERVICE_ENDPOINT = getDMMServiceEndpoint()
export const KAVA_DMM_SERVICE_ENDPOINT =
  'https://k8s.mainnet.olp.kava.grpc-web.injective.network'

export const NOTIFI_CARD_ID = 'd0ee8739c0e5418b9ae1af33ecfd11ce'

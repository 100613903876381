import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as indexonJUy8CH54Meta } from "/opt/build/repo/pages/insurance-fund/index.vue?macro=true";
import { default as pending_45redemptionsZMjLFm9G86Meta } from "/opt/build/repo/pages/insurance-fund/pending-redemptions.vue?macro=true";
import { default as insurance_45fundrBKHoV4BD0Meta } from "/opt/build/repo/pages/insurance-fund.vue?macro=true";
import { default as maintenance0n2g3CNJhCMeta } from "/opt/build/repo/pages/maintenance.vue?macro=true";
import { default as eligibilityw0TNx4uKpnMeta } from "/opt/build/repo/pages/program/liquidity/eligibility.vue?macro=true";
import { default as indexup9A2X1QxvMeta } from "/opt/build/repo/pages/program/liquidity/index.vue?macro=true";
import { default as rewardsMNbmkR76J1Meta } from "/opt/build/repo/pages/program/liquidity/rewards.vue?macro=true";
import { default as scoresTfBvUM7WAjMeta } from "/opt/build/repo/pages/program/liquidity/scores.vue?macro=true";
import { default as snapshotszyMn3cGaTRMeta } from "/opt/build/repo/pages/program/liquidity/snapshots.vue?macro=true";
import { default as liquidityhWy8QGoOaLMeta } from "/opt/build/repo/pages/program/liquidity.vue?macro=true";
export default [
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index",
    path: indexlaFeZuYZkUMeta?.path ?? "/",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: insurance_45fundrBKHoV4BD0Meta?.name ?? undefined,
    path: insurance_45fundrBKHoV4BD0Meta?.path ?? "/insurance-fund",
    meta: insurance_45fundrBKHoV4BD0Meta || {},
    alias: insurance_45fundrBKHoV4BD0Meta?.alias || [],
    redirect: insurance_45fundrBKHoV4BD0Meta?.redirect,
    component: () => import("/opt/build/repo/pages/insurance-fund.vue").then(m => m.default || m),
    children: [
  {
    name: indexonJUy8CH54Meta?.name ?? "insurance-fund",
    path: indexonJUy8CH54Meta?.path ?? "",
    meta: indexonJUy8CH54Meta || {},
    alias: indexonJUy8CH54Meta?.alias || [],
    redirect: indexonJUy8CH54Meta?.redirect,
    component: () => import("/opt/build/repo/pages/insurance-fund/index.vue").then(m => m.default || m)
  },
  {
    name: pending_45redemptionsZMjLFm9G86Meta?.name ?? "insurance-fund-pending-redemptions",
    path: pending_45redemptionsZMjLFm9G86Meta?.path ?? "pending-redemptions",
    meta: pending_45redemptionsZMjLFm9G86Meta || {},
    alias: pending_45redemptionsZMjLFm9G86Meta?.alias || [],
    redirect: pending_45redemptionsZMjLFm9G86Meta?.redirect,
    component: () => import("/opt/build/repo/pages/insurance-fund/pending-redemptions.vue").then(m => m.default || m)
  }
]
  },
  {
    name: maintenance0n2g3CNJhCMeta?.name ?? "maintenance",
    path: maintenance0n2g3CNJhCMeta?.path ?? "/maintenance",
    meta: maintenance0n2g3CNJhCMeta || {},
    alias: maintenance0n2g3CNJhCMeta?.alias || [],
    redirect: maintenance0n2g3CNJhCMeta?.redirect,
    component: () => import("/opt/build/repo/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: liquidityhWy8QGoOaLMeta?.name ?? undefined,
    path: liquidityhWy8QGoOaLMeta?.path ?? "/program/liquidity",
    meta: liquidityhWy8QGoOaLMeta || {},
    alias: liquidityhWy8QGoOaLMeta?.alias || [],
    redirect: liquidityhWy8QGoOaLMeta?.redirect,
    component: () => import("/opt/build/repo/pages/program/liquidity.vue").then(m => m.default || m),
    children: [
  {
    name: eligibilityw0TNx4uKpnMeta?.name ?? "program-liquidity-eligibility",
    path: eligibilityw0TNx4uKpnMeta?.path ?? "eligibility",
    meta: eligibilityw0TNx4uKpnMeta || {},
    alias: eligibilityw0TNx4uKpnMeta?.alias || [],
    redirect: eligibilityw0TNx4uKpnMeta?.redirect,
    component: () => import("/opt/build/repo/pages/program/liquidity/eligibility.vue").then(m => m.default || m)
  },
  {
    name: indexup9A2X1QxvMeta?.name ?? "program-liquidity",
    path: indexup9A2X1QxvMeta?.path ?? "",
    meta: indexup9A2X1QxvMeta || {},
    alias: indexup9A2X1QxvMeta?.alias || [],
    redirect: indexup9A2X1QxvMeta?.redirect,
    component: () => import("/opt/build/repo/pages/program/liquidity/index.vue").then(m => m.default || m)
  },
  {
    name: rewardsMNbmkR76J1Meta?.name ?? "program-liquidity-rewards",
    path: rewardsMNbmkR76J1Meta?.path ?? "rewards",
    meta: rewardsMNbmkR76J1Meta || {},
    alias: rewardsMNbmkR76J1Meta?.alias || [],
    redirect: rewardsMNbmkR76J1Meta?.redirect,
    component: () => import("/opt/build/repo/pages/program/liquidity/rewards.vue").then(m => m.default || m)
  },
  {
    name: scoresTfBvUM7WAjMeta?.name ?? "program-liquidity-scores",
    path: scoresTfBvUM7WAjMeta?.path ?? "scores",
    meta: scoresTfBvUM7WAjMeta || {},
    alias: scoresTfBvUM7WAjMeta?.alias || [],
    redirect: scoresTfBvUM7WAjMeta?.redirect,
    component: () => import("/opt/build/repo/pages/program/liquidity/scores.vue").then(m => m.default || m)
  },
  {
    name: snapshotszyMn3cGaTRMeta?.name ?? "program-liquidity-snapshots",
    path: snapshotszyMn3cGaTRMeta?.path ?? "snapshots",
    meta: snapshotszyMn3cGaTRMeta || {},
    alias: snapshotszyMn3cGaTRMeta?.alias || [],
    redirect: snapshotszyMn3cGaTRMeta?.redirect,
    component: () => import("/opt/build/repo/pages/program/liquidity/snapshots.vue").then(m => m.default || m)
  }
]
  }
]